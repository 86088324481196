import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery, Link } from "gatsby"

import "../../assets/css/galeria.css"
import SideMenu from "../SideMenu"

// Query for Top image, Contact Form background image and page elements bg
export const query = graphql`
  {
    allFile(filter: { sourceInstanceName: { eq: "fotowoltaikaGallery" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            height: 500
          )
        }
      }
    }
  }
`

const Galeria = () => {
  const data = useStaticQuery(query)
  const nodes = data.allFile.nodes
  return (
    <div id="galeria">
      {nodes.map((image, index) => {
        return (
          <article key={index}>
            <div className="galeriaImageSmall">
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                alt={image.name}
              />
            </div>
          </article>
        )
      })}
      <SideMenu base="/fotowoltaika_galeria">
        <Link className="sideMenuOZEBack" to="/ekologiczna_energia">
          <StaticImage
            src="../../assets/images/oze/oze_back.png"
            alt="sterowanie"
            placeholder="tracedSVG"
          />
        </Link>
      </SideMenu>
    </div>
  )
}

export default Galeria
